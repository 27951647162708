<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="id"
			ref="general"
			name-label="Small banner name*"
			:name="name"
			:priority="priority"
			:status="status"
			:widgets="widgets"
			:widget-type="widgetType"
			show-widget
			@update:name="name = $event"
			@update:priority="priority = $event"
			@update:status="status = $event"
			@update:widgets="widgets = $event"
		/>

		<BannerDate
			ref="date"
			:status="status"
			:start-date="startDate"
			:start-time="startTime"
			:end-date="endDate"
			:end-time="endTime"
			@update:startDate="startDate = $event"
			@update:startTime="startTime = $event"
			@update:endDate="endDate = $event"
			@update:endTime="endTime = $event"
		/>

		<BannerContentWrapper
			ref="content"
			desktop-img="/assets/images/small-banner-desktop.svg"
			mobile-img="/assets/images/small-banner-mobile.svg"
			:required-link="!!banner"
			:banner-link="bannerLink"
			@update:bannerLink="bannerLink = $event"
		>
			<template #title>
				Small banner
			</template>
			<BaseMediaSelectorBox
				ref="banner"
				v-model="banner"
				title=""
				no-image-text="No banner"
				show-helper
				width="800"
				height="357"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove banner' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'BannerSmallList'})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBanner.bind(null, id)"
			title="Remove this banner?"
			description="By removing this, all banner info will be disappeared from the list and linked widgets."
			@onSuccess="$router.push({ name: 'BannerSmallList'})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';

import { BANNER_TYPE, BANNER_IMAGE_TYPE } from '../enums/banners';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'BannerSmallForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
		BaseMediaSelectorBox,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.small,
		},
	},

	data() {
		return {
			id: null,
			name: '',
			priority: 0,
			widgets: [],
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			banner: null,
			bannerLink: '',
		};
	},

	computed: {
		widgetType() {
			const widget = {
				hero: 'hero_banner',
				highlight: 'highlight_banner',
				small: 'small_banner',
				// In case text widget type and banner type are same
				text_widget: 'text_widget',
				picture_thumbnail: 'picture_thumbnail',
			};

			return widget[this.bannerType];
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.priority = value.priority;
					this.status = value.status;
					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;
					this.banner = value.banners.banner;
					this.bannerLink = value.banners.bannerLink;
					this.widgets = value.widgets;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteBanner: 'banners/deleteBanner',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		addBanner(file, type, link) {
			const result = {};
			result.file = file ? file.id : null;
			result.type = type;

			if (link) {
				result.link = link;
			}

			return result;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;

			const contentRef = this.$refs.content.$v;
			const bannerRef = this.$refs.banner.$v;

			generalRef.$touch();
			dateRef.$touch();
			contentRef.$touch();
			bannerRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentRef.$invalid &&
				!bannerRef.$invalid
			) {
				const data = {
					name: this.name,
					type: BANNER_TYPE.small,
					priority: Number(this.priority, 10),
					is_visible: this.status,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					banners: [
						this.addBanner(
							this.banner,
							BANNER_IMAGE_TYPE.homepage_small_banner_image,
							this.bannerLink,
						),
					],

					widget_ids: this.widgets.map((widget) => widget.id),
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
